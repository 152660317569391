import { initializeNutasticApp } from '@nutastic/nutastic-react-app';
import { extractElementData, fetchApiData } from '@nutastic/nutastic-react-app/src/utils';
import AppLoading from './components/AppLoading';
import App from './components/App';
import WebFont from 'webfontloader';
import messages from './i18n/messages';
import websiteReducer from './state/reducer.mjs';

const init = async () => {
    WebFont.load({
        google: {
            families: [
                'Nanum+Myeongjo:wght@400;700',
                'Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900'
            ]
        }
    });

    console.log('initializing page');
    await initializeNutasticApp({
        AppComponent: App,
        LoadingComponent: AppLoading,
        initialState: extractElementData(),
        decorateStateFn: fetchApiData,
        loadingDelay: 1000,
        messages,
        reducers: [websiteReducer]
    });
};

init();
