"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _default = function _default(toggleAnimation, _ref) {
  var disabled = _ref.disabled,
      onHover = _ref.onHover;
  (0, _react.useLayoutEffect)(function () {
    var isAnimationEnabled = !disabled && !onHover;

    if (isAnimationEnabled) {
      setTimeout(toggleAnimation.add, 0);
    } else toggleAnimation.remove();
  }, [disabled, onHover]);
};

exports.default = _default;