import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContainer, useAppState } from '@nutastic/nutastic-react-app';
import ComingSoonPage from "./pages/ComingSoonPage";

const App = () => {
    const renderPageRoute = () => {
        return <Switch>
            <Route path={['/', '/home']} component={ComingSoonPage}/>
        </Switch>;
    };

    return <AppContainer>
        { renderPageRoute() }
    </AppContainer>;
};

export default App;
