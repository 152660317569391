"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _default = function _default(ref, glitchId) {
  var add = (0, _react.useCallback)(function () {
    var _ref$current;

    (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.setAttribute('data-animation', glitchId);
    return ref;
  }, []);
  var remove = (0, _react.useCallback)(function () {
    var _ref$current2;

    (_ref$current2 = ref.current) === null || _ref$current2 === void 0 ? void 0 : _ref$current2.removeAttribute('data-animation');
    return ref;
  }, []);
  return {
    add: add,
    remove: remove
  };
};

exports.default = _default;