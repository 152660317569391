import {WebsiteActions} from "./actions";
import {notifyError, notifySuccess} from "@nutastic/nutastic-react-app";

const reducer = (state, action) => {
    switch (action.type) {
        case WebsiteActions.ORDER_SUBMIT_COMPLETED: {
            notifySuccess('Your email has successfully been sent');
            return {
                ...state
            }
        }

        case WebsiteActions.ORDER_SUBMIT_FAILED: {
            notifyError('emailError', 'Error', 'Your email failed to send. Try again!');
            return {
                ...state
            }
        }

        default:
            return state;
    }
};

export default reducer;
