"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _default = function _default(ref, _ref) {
  var iterationCount = _ref.iterationCount,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 0 : _ref$duration;
  (0, _react.useLayoutEffect)(function () {
    var _ref$current;

    (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.style.setProperty('--iteration-count', iterationCount);
  }, [iterationCount]);
  (0, _react.useLayoutEffect)(function () {
    var _ref$current2;

    var durationMs = "".concat(duration, "ms");
    (_ref$current2 = ref.current) === null || _ref$current2 === void 0 ? void 0 : _ref$current2.style.setProperty('--duration', durationMs);
  }, [duration]);
};

exports.default = _default;