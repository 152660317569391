"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _default = function _default(ref, restCssVarList) {
  (0, _react.useLayoutEffect)(function () {
    JSON.parse(restCssVarList).forEach(function (_ref) {
      var _ref$current;

      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          name = _ref2[0],
          value = _ref2[1];

      (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.style.setProperty(name, value);
    });
  }, [restCssVarList]);
};

exports.default = _default;