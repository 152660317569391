import React from 'react';
import { getPublicImagePath } from '@nutastic/nutastic-react-app';
import { ReactSVG } from 'react-svg';

const AppLoading = () => {
    const componentClass = 'appLoadingPage';
    return <div className={componentClass}>
        <ReactSVG src={getPublicImagePath('loading-eclipse.svg')} className={`${componentClass}__loadingIcon`} />
    </div>;
};

export default AppLoading;
