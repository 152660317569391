import React, {useState} from 'react';
import {getPublicImagePath, useAppState, getUploadedImagePath} from '@nutastic/nutastic-react-app';
import PageSlider from "../PageSlider";
import {ReactSVG} from "react-svg";
import VideoPlayer from "../VideoPlayer";

//A-RayTV 2023 Coming Soon Page
const ComingSoonPage = () => {
    const { slides = [] } = useAppState();
    const [currentVideo, setCurrentVideo] = useState(null);
    const componentClass = 'coming-soon-page';
    const pageSlides = slides.map(slide => {
        return {
            image: getUploadedImagePath(slide.image),
            link: slide.link
        }
    });

    return <React.Fragment>
        <header className={`${componentClass}__header`}>
            <ReactSVG src={ getPublicImagePath('araytv-logo.svg') } />
        </header>
        <PageSlider slides={pageSlides} onSlideClick={v => setCurrentVideo(v)} shouldDisableGlitch={!!currentVideo} />
        <VideoPlayer videoId={currentVideo} onClose={() => setCurrentVideo(null)} />
    </React.Fragment>
};

export default ComingSoonPage;
