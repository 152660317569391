"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _default = function _default(_ref) {
  var callbackEvents = _ref.callbackEvents,
      onHover = _ref.onHover,
      toggleAnimation = _ref.toggleAnimation;
  var handleOnMouseEnter = (0, _react.useCallback)(function (e) {
    var isOnMouseEnter = typeof callbackEvents.onMouseEnter === 'function';
    if (isOnMouseEnter) callbackEvents.onMouseEnter(e);
    if (onHover) toggleAnimation.add();
    return e;
  }, [onHover, callbackEvents.onMouseEnter]);
  var handleOnMouseLeave = (0, _react.useCallback)(function (e) {
    var isOnMouseLeave = typeof callbackEvents.onMouseEnter === 'function';
    if (isOnMouseLeave) callbackEvents.onMouseLeave(e);
    if (onHover) toggleAnimation.remove();
    return e;
  }, [onHover, callbackEvents.onMouseLeave]);
  return {
    handleOnMouseEnter: handleOnMouseEnter,
    handleOnMouseLeave: handleOnMouseLeave
  };
};

exports.default = _default;