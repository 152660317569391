import React from 'react';
import { Modal } from '@nutastic/nutastic-react-app';
import Youtube from '@u-wave/react-youtube';

const VideoPlayer = ({ onClose, videoId }) => {
    const componentClass = 'videoPlayer';
    const isActive = !!videoId;

    return <Modal show={isActive} closeBtn={<i className="ion-md-close-circle-outline"/> } onClose={onClose} className={componentClass} nodeId="root">
        { isActive && <Youtube video={videoId} autoplay/> }
    </Modal>;
};

export default VideoPlayer;
