
const WebsiteActions = {
    ORDER_SUBMIT_STARTED: 'ORDER_SUBMIT_STARTED',
    ORDER_SUBMIT_COMPLETED: 'ORDER_SUBMIT_COMPLETED',
    ORDER_SUBMIT_FAILED: 'ORDER_SUBMIT_FAILED'
};

const triggerAction = type => {
    return { type };
};

const sendOrderSubmission = data => {
    return async dispatch => {
        dispatch( triggerAction(WebsiteActions.ORDER_SUBMIT_STARTED) );
        try {
            const sendResult = await fetch("/submitOrderForm", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify(data)
            });
            dispatch( triggerAction( sendResult.status === 200 ? WebsiteActions.ORDER_SUBMIT_COMPLETED : WebsiteActions.ORDER_SUBMIT_FAILED) );
        } catch (ex) {
            dispatch( triggerAction(WebsiteActions.ORDER_SUBMIT_FAILED) );
        }
    };
};

export {
    WebsiteActions,
    sendOrderSubmission
};
